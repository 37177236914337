<template>
  <div class="container">
    <div class="wrap">
      <el-form ref="set-password-form" class="wrap-form" :model="passwordForm" :rules="passwordFormRules">
        <el-form-item prop="password">
          <el-input
            type="password"
            placeholder="请输入6-16位英文及数字组合密码"
            maxlength="16"
            v-model="passwordForm.password"
          />
        </el-form-item>
        <el-form-item prop="repassword">
          <el-input
            type="password"
            placeholder="请再次输入上面的密码"
            maxlength="16"
            v-model="passwordForm.repassword"
          />
        </el-form-item>
        <el-form-item>
          <div class="button-radius ok-button font-s20" @click="submit">确定</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { service } from '@/utils/axios'
import store from '@/store'
export default {
  data() {
    // 验证
    const validatePassword = (_, value, callback) => {
      if (!value) {
        return callback(new Error('请输入密码'))
      }
      if (!/^(?![a-zA-z]+$)(?!\d+$)[a-zA-Z\d_]{6,16}$/.test(value)) {
        return callback(new Error('密码必须是6-16位英文和数字组合'))
      }
      if (this.passwordForm.repassword !== '') {
        this.$refs['set-password-form'].validateField('repassword')
      }
      callback()
    }
    const validateRepassword = (_, value, callback) => {
      if (!value) {
        return callback(new Error('请再次输入密码'))
      }
      if (value != this.passwordForm.password) {
        return callback(new Error('两次输入的密码不一致'))
      }
      callback()
    }

    return {
      passwordForm: {
        password: '',
        repassword: '',
      },
      passwordFormRules: {
        password: { require: true, validator: validatePassword, trigger: 'blur' },
        repassword: { require: true, validator: validateRepassword, trigger: 'blur' },
      },
    }
  },
  methods: {
    async submit() {
      this.$refs['set-password-form'].validate(async v => {
        if (v) {
          const res = await service.post('/app/first_reset_password', { new_pwd: this.passwordForm.password })
          if (res.data.ret_code === 0) {
            store.commit('user/SET_HAS_PWD')
            this.$message.success('设置密码成功，即将跳转首页')
            setTimeout(() => this.$router.push('/'), 1000)
          }
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  padding-top: 30px;
  padding-bottom: 30px;
  .wrap {
    max-width: 1200px;
    height: 600px;
    background-color: #ffffff;
    background-clip: content-box;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    &-form {
      width: 500px;
      margin: 0 auto;
      padding-top: 60px;
    }
    /deep/ .el-input {
      width: 455px;
    }
    .send-button {
      width: 145px;
      margin-left: 10px;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
    }
    .disabled {
      cursor: default;
      background: #fff;
      border: 1px solid #999;
      box-sizing: border-box;
      color: #999;
      text-align: center;
    }
    .ok-button {
      width: 455px;
      color: #ffffff;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
